<template>
  <el-dialog
    :title="`${form.id ? '编辑' : '新增'}标签群发`"
    :visible.sync="show"
    :fullscreen="true"
    center
    :before-close="handleClose"
  >
    <el-row :gutter="20">
      <el-form
        label-position="left"
        label-width="120px"
        class="form-container"
        v-loading="formLoading"
      >
        <!-- 表单顶部 -->
        <div class="form-top">
          <el-form-item label="公众号：" class="necessary">
            <el-select
              style="width: 100%"
              v-model="selectChannel"
              @change="handleSelectChannel"
              value-key="id"
              filterable
              placeholder="公众号"
              :disabled="!!info"
            >
              <el-option
                v-for="item in account"
                :key="item.id"
                :value="item"
                :label="item.channelName"
              >
                <span style="float: left">{{ item.channelName }}</span>
                <span style="float: right; margin-left: 15px; font-size: 13px"
                  >{{ item.platformStr }} - {{ item.uname }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：" class="necessary">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </div>
        <!-- 表单中部 -->
        <div class="form-body">
          <div class="form-body-left">
            <el-form-item label="消息类型：">
              <el-radio-group v-model="form.msgType" size="medium">
                <el-radio-button label="text">文本</el-radio-button>
                <el-radio-button label="mpnews">图文</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="消息内容："
              class="necessary"
              style="height: 420px"
            >
              <template v-if="form.msgType === 'text'">
                <el-input
                  type="textarea"
                  class="test"
                  style="width: 450px"
                  :autosize="{ minRows: 16 }"
                  placeholder="请输入内容"
                  @blur="handleBlur"
                  v-model="textStr"
                >
                </el-input>
                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                  "
                >
                  <el-button
                    class="button-mini"
                    type="text"
                    style="padding-right: 10px"
                    @click=";(showTitleDialog = true), (insertInfoType = 1)"
                    >插入标题</el-button
                  >
                  <!-- <insert-book
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  ></insert-book>
                  <insert-page
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  >
                  </insert-page> -->
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="handleInsert('${nickname}}')"
                    >插入用户昵称</el-button
                  >
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="showMiniDialog = true"
                    >插入小程序</el-button
                  >
                  <!-- <insert-activity
                    :channel="selectChannel"
                    :type="form.msgType"
                    @success="handleActivityInsert"
                  /> -->
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="showTemplate = true"
                    >插入模板</el-button
                  >
                  <!-- <el-button
                    class="button-mini"
                    type="text"
                    @click="checkCoinStatus"
                    v-if="[0, 1, 3, 6, 7].includes(selectChannel.platform)"
                    >插入书币</el-button
                  > -->
                </div>
              </template>
              <el-form v-if="form.msgType === 'mpnews'" label-width="50px">
                <div style="margin-left: 10px">
                  <el-radio v-model="materialType" :label="1"
                    >历史素材</el-radio
                  >
                  <el-radio v-model="materialType" :label="2"
                    >草稿素材</el-radio
                  >
                </div>
                <div
                  class="image-text-box"
                  @click="handleSelectMaterial"
                  style="margin-right: 10px"
                >
                  <!-- <span>{{ selectMaterial ? selectMaterial.title : "" }}</span> -->
                  <div class="material-item" v-if="selectMaterial">
                    <ul class="article-box">
                      <template v-if="typeof selectMaterial.title === 'string'">
                        <li
                          class="article-item"
                          v-for="(item2, index2) in JSON.parse(
                            selectMaterial.title
                          )"
                          :key="index2"
                        >
                          <p>{{ item2.title }}</p>
                          <img :src="item2.url" />
                        </li>
                      </template>
                      <template v-else>
                        <li
                          class="article-item"
                          v-for="(item2, index2) in selectMaterial.title"
                          :key="index2"
                        >
                          <p>{{ item2.title }}</p>
                          <img :src="item2.url" />
                        </li>
                      </template>
                    </ul>
                  </div>
                  <p v-if="!selectMaterial"><i class="el-icon-plus"></i></p>
                </div>
                <!-- <el-button type="text" @click="handleSelectMaterial"
                  >选择素材</el-button
                > -->
              </el-form>
            </el-form-item>
            <el-form-item label="标签类型">
              <el-radio-group
                @change="handleChangeType"
                v-model="form.type"
                size="medium"
              >
                <el-radio-button :label="1">标签发送</el-radio-button>
                <el-radio-button :label="2">关注发送</el-radio-button>
                <el-radio-button :label="3">循环发送</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              class="necessary"
              v-if="form.type !== 1"
              label="发送间隔"
            >
              <el-input-number
                style="width: 180px"
                v-model="form.subDay"
                :step-strictly="true"
                :step="1"
                controls-position="right"
                :min="1"
              ></el-input-number>
              <span style="margin-left: 10px">天</span>
              <el-popover placement="right" width="500" trigger="hover">
                <p>
                  第一次发送以定时时间为基准，接收用户= 定时时间 -
                  发送间隔，进行计算
                </p>
                <i
                  v-if="form.type == 2"
                  slot="reference"
                  style="margin-left: 20px"
                  class="el-icon-question"
                ></i>
              </el-popover>
            </el-form-item>
            <el-form-item label="定时时间：">
              <el-date-picker
                style="width: 500px"
                v-model="form.tick"
                :picker-options="{
                  disabledDate(time) {
                    return time < Date.now()
                  },
                }"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择时间"
              >
              </el-date-picker>
            </el-form-item>
            <!-- 新增互动类型 -->
            <el-form-item label="互动类型">
              <el-radio v-model="form.tagType" :label="0">全部</el-radio>
              <el-radio v-model="form.tagType" :label="1">活跃</el-radio>
              <el-radio v-model="form.tagType" :label="2">不活跃</el-radio>
            </el-form-item>
            <el-form-item label="时间间隔">
              <c-input
                style="width: 180px"
                v-model="form.hourSpaceStart"
                :onlyNum="true"
              ></c-input>
              <span> 到 </span>
              <c-input
                style="width: 180px"
                v-model="form.hourSpaceEnd"
                :onlyNum="true"
              ></c-input>
              <span>天</span>
            </el-form-item>
            <!-- 当天统计信息 -->
            <el-form-item label="发送次数排除">
              <el-input-number
                style="width: 180px"
                v-model="form.minSendNum"
                placeholder="最小发送数量"
                :min="0"
              ></el-input-number>
              <span style="margin: 0 10px"></span>
              <el-input-number
                style="width: 180px"
                v-model="form.maxSendNum"
                placeholder="最大发送数量"
                :min="0"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="接收用户">
              <el-radio-group v-model="form.sendAll" size="medium">
                <el-radio-button :disabled="form.type != 1" :label="1"
                  >全部用户</el-radio-button
                >
                <el-radio-button :label="0">标签用户</el-radio-button>
              </el-radio-group>
              <!-- <el-radio v-model="form.sendAll" :label="1">全部用户</el-radio>
              <el-radio v-model="form.sendAll" :label="0">标签用户</el-radio> -->

              <!-- <div>
              <el-button type="text" @click="getNum"
                >点击查看预计可送达人数</el-button
              >
              <span
                v-if="typeof estimateNum === 'number'"
                style="margin-left: 20px"
                >预计可送达：{{ estimateNum }}人</span
              >
            </div> -->
            </el-form-item>
            <el-form-item label="测试人">
              <!--        <el-input v-model="testId" style="width: 350px;margin-right: 10px"/>-->
              <el-select
                v-model="testId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入用户名"
                :remote-method="userQuery"
                :loading="remoteLoading"
              >
                <el-option
                  v-for="(item, index) in testUser"
                  :key="item.openId + 'user' + index"
                  :label="item.nickname"
                  :value="item.openId"
                >
                  <div class="row">
                    <user-avatar :info="item" name-prop="nickname" :size="25" />
                    <span style="margin-left: 10px">{{ item.nickname }}</span>
                  </div>
                </el-option>
              </el-select>
              <el-button
                style="margin-left: 10px"
                type="primary"
                @click="handleTest"
                :loading="testLoading"
                >发送</el-button
              >
            </el-form-item>
          </div>
          <div class="form-body-right">
            <el-form label-width="120px" v-loading="formLoading">
              <el-form label-width="85px">
                <!-- 基础属性新增结束 -->

                <el-form-item label="充值情况">
                  <el-radio-group v-model="form.rechargeType" size="medium">
                    <el-radio-button :label="0">不限</el-radio-button>
                    <el-radio-button :label="1">未充值</el-radio-button>
                    <el-radio-button :label="2">已充值</el-radio-button>
                  </el-radio-group>
                  <!-- <el-radio-group v-model="form.rechargeType">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="1">未充值</el-radio>
                    <el-radio :label="2">已充值</el-radio>
                  </el-radio-group> -->
                </el-form-item>
                <div class="inner-form" v-if="form.rechargeType === 2">
                  <el-form-item label="充值间隔" v-if="form.rechargeType === 2">
                    <el-input-number
                      v-model="form.rechargeTimeSpaceStart"
                      :step-strictly="true"
                      :step="1"
                      placeholder="开始天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      v-model="form.rechargeTimeSpaceEnd"
                      :step-strictly="true"
                      :step="1"
                      placeholder="结束天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <!-- 阳光书城显示剩余金币 -->
                  <el-form-item
                    label="剩余书币"
                    v-if="
                      form.rechargeType === 2 &&
                      (selectChannel.platform === 3 ||
                        selectChannel.platform === 0)
                    "
                  >
                    <el-input-number
                      v-model="form.minKanDian"
                      placeholder="最小剩余书币"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      v-model="form.maxKanDian"
                      placeholder="最大剩余书币"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="书币消耗率"
                    v-if="form.rechargeType === 2"
                  >
                    <el-input-number
                      v-model="form.minKanDianRate"
                      placeholder="最小书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    >
                    </el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      v-model="form.maxKanDianRate"
                      placeholder="最大书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    >
                    </el-input-number>
                  </el-form-item>
                  <el-form-item label="累计充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      v-model="form.minTotalRecharge"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      placeholder="最大充值"
                      v-model="form.maxTotalRecharge"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="最后充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      v-model="form.minLastRechargeAmt"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      placeholder="最大充值"
                      v-model="form.maxLastRechargeAmt"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="充值次数" v-if="form.rechargeType === 2">
                    <el-input-number
                      v-model="form.minRechargeCount"
                      placeholder="最小次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      v-model="form.maxRechargeCount"
                      placeholder="最大次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>

                  <!-- 统计属性 -->
                  <!-- 最近7日价值 -->
                  <el-form-item
                    label="近7日充值"
                    v-if="form.rechargeType === 2"
                  >
                    <el-input-number
                      v-model="form.minSevenRecharge"
                      placeholder="最小充值"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      v-model="form.maxSevenRecharge"
                      placeholder="最大充值"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <div class="vip-consume">
                    <!-- 是否vip -->
                    <el-form-item
                      style="width: 220px"
                      label="VIP"
                      label-width="45px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-radio-group v-model="form.isVip">
                        <el-radio :label="2">不限</el-radio>
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <!-- 连续三周均有充值 -->

                    <div class="consume-box" style="width: 60%">
                      <el-tooltip
                        style="margin-left: 20px"
                        class="item"
                        effect="dark"
                        content="用户最近三周内每周均有充值"
                        placement="left"
                      >
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <el-form-item
                        label-width="80px"
                        label="连续付费"
                        v-if="form.rechargeType === 2"
                      >
                        <el-radio-group v-model="form.continuousRecharge">
                          <el-radio :label="0">不限</el-radio>
                          <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- rfm -->
                  <div class="rfm-box">
                    <el-popover placement="right" width="500" trigger="hover">
                      <el-table :data="gridData" height="300">
                        <el-table-column
                          width="120"
                          property="label"
                          label="客户标签"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="R"
                          label="R"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="F"
                          label="F"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="M"
                          label="M"
                        ></el-table-column>
                        <el-table-column
                          property="scene"
                          label="场景"
                        ></el-table-column>
                        <el-table-column
                          width="50"
                          property="level"
                          label="等级"
                        ></el-table-column>
                      </el-table>
                      <i
                        slot="reference"
                        style="margin-left: 20px"
                        class="el-icon-question"
                      ></i>
                    </el-popover>
                    <el-form-item
                      label="RFM"
                      label-width="60px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-select
                        v-model="form.rfm"
                        style="width: 350px"
                        placeholder="请选择rfm"
                        multiple
                        clearable
                      >
                        <!-- <el-option label="不限" :value="0"> </el-option> -->
                        <el-option label="A类" :value="1"></el-option>
                        <el-option label="B类" :value="2"></el-option>
                        <el-option label="C类" :value="3"></el-option>
                      </el-select>
                      <!-- 解释表格 -->
                    </el-form-item>
                  </div>
                  <!-- 剩余优惠券 -->
                  <el-form-item
                    label="优惠敏感度"
                    class="add-element"
                    v-if="form.rechargeType === 2"
                  >
                    <el-select
                      v-model="form.couponLevel"
                      placeholder="请选择优惠券级别"
                    >
                      <el-option label="不限" :value="0"> </el-option>
                      <el-option label="1级" :value="1"> </el-option>
                      <el-option label="2级" :value="2"> </el-option>
                      <el-option label="3级" :value="3"> </el-option>
                      <el-option label="4级" :value="4"> </el-option>
                      <el-option label="5级" :value="5"> </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="书籍"
                    class="add-element"
                    v-if="form.rechargeType === 2"
                  >
                    <el-select
                      v-model="form.bookId"
                      filterable
                      remote
                      style="width: 350px"
                      reserve-keyword
                      clearable
                      placeholder="请输入书籍名称"
                      :remote-method="getBooks"
                      :loading="loading"
                    >
                      <el-option
                        v-if="defaultBook"
                        :label="defaultBook.bookName"
                        :value="defaultBook.id"
                      ></el-option>
                      <el-option
                        v-for="item in bookList"
                        :key="item.id + 'book'"
                        :label="item.bookName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form>
              <template>
                <el-form-item label="性别">
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- 基础属性新增 -->
                <el-form-item label="所在地区">
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.province"
                    filterable
                    placeholder="省份"
                    clearable
                  >
                    <el-option
                      v-for="item in area"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.city"
                    filterable
                    placeholder="城市"
                    clearable
                  >
                    <el-option
                      v-for="item in areaCity"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item v-if="form.type == 1" label="关注时间">
                  <el-date-picker
                    v-model="form.startDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px"
                    placeholder="开始时间"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                  <el-date-picker
                    v-model="form.endDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px; margin-left: 10px"
                    placeholder="结束时间"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="签到次数">
                  <el-input-number
                    style="width: 180px; margin-right: 10px"
                    v-model="form.minSignInCount"
                    placeholder="最小签到次数"
                    :min="0"
                    :step="1"
                  >
                  </el-input-number>

                  <el-input-number
                    style="width: 180px"
                    v-model="form.maxSignInCount"
                    placeholder="最大签到次数"
                    :min="0"
                    :step="1"
                  >
                  </el-input-number>
                </el-form-item>
              </template>
            </el-form>
            <div class="form-body-right-mask" v-if="form.sendAll !== 0">
              <p>仅标签用户可操作</p>
            </div>
          </div>
        </div>
      </el-form>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <div class="sub-box">
        <div class="button-box">
          <el-button @click="handleClose">取 消</el-button>
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="handleSubmit"
            :loading="submitLoading"
            >确 定</el-button
          >
        </div>
        <div style="display: flex; justify-content: center">
          <span>
            <el-button type="text" @click="getNum"
              >点击查看预计可送达人数</el-button
            >
            <span
              @click="showDetail = true"
              v-if="typeof estimateNum === 'number'"
              :class="estimateNum ? 'person-count' : ''"
              style="margin-left: 20px; font-size: 14px"
              >预计可送达：{{ estimateNum }}人</span
            >
          </span>
        </div>
      </div>
    </span>
    <!-- <el-drawer
      title="选择素材"
      size="800px"
      :visible.sync="showMaterialSelect"
      :append-to-body="true"
    > -->
    <el-dialog
      title="选择素材"
      width="900px"
      top="100px"
      :visible.sync="showMaterialSelect"
      :append-to-body="true"
    >
      <select-material
        :materialType="materialType"
        :show="showMaterialSelect"
        @close="showMaterialSelect = false"
        :account-id="selectChannel ? selectChannel.id : null"
        @success="(select) => (this.selectMaterial = select)"
      />
    </el-dialog>
    <!-- </el-drawer> -->
    <el-dialog
      title="插入小程序"
      width="500px"
      :visible.sync="showMiniDialog"
      :append-to-body="true"
    >
      <insert-mini-program
        :show="showMiniDialog"
        @close="showMiniDialog = false"
        @success="handleInsert"
      />
    </el-dialog>
    <!-- <el-drawer
      title="选择标题"
      :visible.sync="showTitleDialog"
      size="600px"
      :append-to-body="true"
    > -->
    <select-title
      :show="showTitleDialog"
      :classifyList="classifyList"
      :insertInfoType="insertInfoType"
      @close="showTitleDialog = false"
      @success="handleInsertBookTitle"
    />
    <!-- </el-drawer> -->
    <!-- <el-drawer
      title="插入模板"
      size="700px"
      :visible.sync="showTemplate"
      :append-to-body="true"
    > -->
    <InsertTemplate
      :classifyList="classifyList"
      :show="showTemplate"
      @close="showTemplate = false"
      @handleOk="handleInsertTemplate"
    ></InsertTemplate>
    <!-- </el-drawer> -->
    <!-- 发送人详情 -->
    <SendDetailTable
      :params="getNumParams"
      @closeDetail="closeDetail"
      :show="showDetail"
      type="tag"
    />
    <!-- 插入书币 -->
    <insert-book-coin
      :show="showBookCoin"
      :channel="selectChannel"
      :type="form.msgType"
      @close="showBookCoin = false"
      @success="handleBookCoinInsert"
    ></insert-book-coin>
  </el-dialog>
</template>

<script>
import area from '@/assets/js/area.js'
import { formatTime } from '@/assets/js/utils.js'
import SelectMaterial from './selectMaterialCopy'
import selectTitle from './../../service/components/selectTitle'
import InsertMiniProgram from '../../service/components/insertMiniProgram'
import UserAvatar from '../../../components/UserAvatar'
import InsertBook from '../../service/insertBook'
import InsertTemplate from './../../service/components/insertTemplate'
import InsertPage from '../../service/insertPage'
import InsertActivity from '../../service/insertActivity'
import { getBookList } from '../../../api/account'
import { getClassifyList } from '@/api/classify'
import SendDetailTable from '../../service/components/sendDetailTable.vue'
import InsertBookCoin from './../../service/insertBookCoin'

import {
  editSendTag,
  getSendtTagDetatl,
  testSendTag,
  getSendTagNum,
  getAccountUser,
} from '../../../api/service'
import { messagePickerOptions } from '@/assets/js/options'

const defaultForm = {
  msgType: 'text',
  sendAll: 1,
  continuousRecharge: 0,
  sex: 0,
  rechargeType: 2,
  isVip: 2,
  rfm: 0,
  couponLevel: 0,
  hourSpaceStart: null,
  hourSpaceEnd: null,
  bookId: null,
  remark: '',
  novelName: '',
  tagType: 0,
  type: 1,
}
export default {
  name: 'edit',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    account: {
      type: Array,
      default: () => [],
    },
    info: Object,
    accountId: Number,
  },
  data() {
    return {
      showMaterialSelect: false,
      showMiniDialog: false,
      selectMaterial: null,
      testId: null,
      form: {},
      newsForm: {
        picurl: '',
        url: '',
        description: '',
        title: '',
      },
      textStr: '', // 文本内容
      textIndex: 0,
      bookList: [],
      loading: false,
      formLoading: false,
      defaultBook: null,
      submitLoading: false,
      remoteLoading: false,
      selectChannel: null, // 选中公众号
      testUser: [],
      classifyList: [],
      showTemplate: false,
      insertInfoType: 1, // 判断是在文本还是在图片的消息类型中插入标题(1是文本，2是图片)
      showTitleDialog: false,
      pickerOptions: messagePickerOptions,
      estimateNum: null,
      testLoading: false,
      showBookCoin: false,
      // 新增客户信息相关
      area,
      areaCity: [],
      gridData: [
        {
          label: '重要价值客户',
          R: '高',
          F: '高',
          M: '高',
          scene: '最近充值-高频次-高金额',
          level: 'A类',
        },
        {
          label: '重要深耕客户',
          R: '高',
          F: '低',
          M: '高',
          scene: '最近充值-低频次-高金额',
          level: 'A类',
        },
        {
          label: '重要唤回客户',
          R: '低',
          F: '高',
          M: '高',
          scene: '最近未充-高频次-高金额',
          level: 'B类',
        },
        {
          label: '重要挽留客户',
          R: '低',
          F: '低',
          M: '高',
          scene: '最近未充-低频次-高金额',
          level: 'B类',
        },
        {
          label: '潜力客户',
          R: '高',
          F: '高',
          M: '低',
          scene: '最近充值-高频次-低金额',
          level: 'B类',
        },
        {
          label: '一般发展客户',
          R: '高',
          F: '低',
          M: '低',
          scene: '最近充值-低频次-低金额',
          level: 'B类',
        },
        {
          label: '一般维持客户',
          R: '低',
          F: '高',
          M: '低',
          scene: '最近未充-高频次-低金额',
          level: 'B类',
        },
        {
          label: '流失客户',
          R: '低',
          F: '低',
          M: '低',
          scene: '最近未充-低频次-低金额',
          level: 'C类',
        },
      ],
      // 获取人数参数
      getNumParams: {},
      showDetail: false,
      // 图文新增素材类型
      materialType: 1,
      canSub: true,
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.estimateNum = null
        this.canSub = true
        this.userQuery()
        if (this.info) {
          this.initData()
        } else {
          this.form = { ...defaultForm }
          this.newsForm = {
            picurl: '',
            url: '',
            description: '',
            title: '',
          }
          this.textStr = ''
          this.selectMaterial = null
          const defaultAccount = this.account.find(
            (item) => item.id === this.accountId
          )
          this.selectChannel = {
            id: this.accountId,
            channelName: defaultAccount ? defaultAccount.channelName : '',
            platform: defaultAccount ? defaultAccount.platform : null,
          }
        }
      }
    },
    'form.province': {
      handler(newValue, oldValue) {
        this.area.forEach((item) => {
          if (item.name === this.form.province) {
            this.areaCity = item.children
          }
        })
        if (newValue === '') {
          this.areaCity = []
        }
        if (oldValue !== undefined) {
          this.$set(this.form, 'city', null)
        }
      },
    },
    'form.type': {
      handler(nv, ov) {
        if (nv === 2 || nv === 3) {
          this.$set(this.form, 'sendAll', 0)
        }
      },
      immediate: true,
    },
    materialType: function (nv) {
      this.selectMaterial = null
    },
  },
  mounted() {
    getBookList({ page: 1, pageSize: 50 }).then((res) => {
      this.bookList = res.list
    })
    getClassifyList({ type: 1, status: 1 }).then((res) => {
      this.classifyList = res
    })
  },
  methods: {
    handleSelectChannel() {
      this.selectMaterial = null
      this.materialType = 1
    },
    handleChangeType(val) {
      if (val === 2) {
        this.$set(this.form, 'rechargeType', 0)
      }
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false
    },
    handleInsertTemplate(val) {
      const { content } = val
      this.handleInsert(content)
    },
    handleInsertBookTitle(title) {
      if (this.insertInfoType === 1) {
        this.handleInsert(title)
      }
    },
    userQuery(name) {
      this.remoteLoading = true
      getAccountUser(this.accountId, name)
        .then((res) => {
          this.testUser = res
        })
        .finally(() => (this.remoteLoading = false))
    },
    handleBlur(event) {
      this.textIndex = event.srcElement.selectionStart
    },
    handleInsert(str) {
      const { textStr, textIndex } = this
      this.textStr =
        textStr.slice(0, textIndex) + str + textStr.slice(textIndex)
    },
    handleSelectMaterial() {
      if (this.selectChannel) {
        this.showMaterialSelect = true
      } else {
        this.$message.warning('请先选择公众号')
      }
    },
    getNum() {
      if (this.selectChannel) {
        const params = {
          accountChannelId: this.selectChannel.id,
          sendAll: this.form.sendAll,
          hourSpaceStart: this.form.hourSpaceStart,
          hourSpaceEnd: this.form.hourSpaceEnd,
          tagType: this.form.tagType,
          minSendNum: this.form.minSendNum,
          maxSendNum: this.form.maxSendNum,
        }
        if (this.form.sendAll !== 1) {
          params.rechargeType = this.form.rechargeType
          params.bookId = this.form.bookId
          params.sex = this.form.sex
          params.startDate = this.form.startDate
          params.endDate = this.form.endDate
          params.rechargeTimeSpaceStart = this.form.rechargeTimeSpaceStart
          params.rechargeTimeSpaceEnd = this.form.rechargeTimeSpaceEnd
          params.minTotalRecharge = this.form.minTotalRecharge
          params.maxTotalRecharge = this.form.maxTotalRecharge
          // 最大最小最后充值
          params.minLastRechargeAmt = this.form.minLastRechargeAmt
          params.maxLastRechargeAmt = this.form.maxLastRechargeAmt
          params.minRechargeCount = this.form.minRechargeCount
          params.maxRechargeCount = this.form.maxRechargeCount
          // 新增城市选择
          params.province = this.form.province
          params.city = this.form.city
          params.continuousRecharge = this.form.continuousRecharge
          params.maxSevenRecharge = this.form.maxSevenRecharge
          params.minSevenRecharge = this.form.minSevenRecharge
          params.minSendNum = this.form.minSendNum
          params.maxSendNum = this.form.maxSendNum
          params.isVip = this.form.isVip
          params.rfm =
            Array.from(this.form.rfm) && this.form.rfm.length === 0
              ? '0'
              : this.form.rfm.join(',')
          params.couponLevel = this.form.couponLevel
        }
        // 新添加阳光金币展示
        if (
          (this.selectChannel.platform === 3 ||
            this.selectChannel.platform === 0) &&
          this.form.rechargeType === 2
        ) {
          params.minKanDian = this.form.minKanDian
          params.maxKanDian = this.form.maxKanDian
        }
        // 若先择关注发送 传入标签类型、发送类型、定时时间
        params.type = this.form.type
        if (this.form.type !== 1) {
          params.subDay = this.form.subDay
          params.tick = this.form.tick
        }
        this.getNumParams = params
        getSendTagNum(params).then((res) => {
          this.estimateNum = typeof res === 'number' ? res : 0
        })
      } else {
        this.$message.error('请选择公众号')
      }
    },
    handleTest() {
      const { msgType } = this.form
      const { textStr, selectMaterial, testId } = this
      if (!this.selectChannel) {
        this.$message.error('请选择公众号')
        return false
      }
      if (msgType === 'text' && !textStr) {
        this.$message.error('请输入发送内容')
        return false
      }
      if (msgType === 'mpnews' && !selectMaterial) {
        this.$message.error('请选择素材')
        return false
      }
      if (!testId) {
        this.$message.error('请输入测试openid')
        return false
      }
      const params = {
        accountChannelId: this.selectChannel.id,
        msgtype: msgType,
        touser: testId,
      }
      if (msgType === 'text') {
        params.text = { content: textStr }
      } else {
        params.mpnews = {
          media_id: selectMaterial.mediaId,
        }
      }
      this.testLoading = true
      testSendTag(params)
        .then(() => {
          this.$message.success('发送成功')
        })
        .finally(() => (this.testLoading = false))
    },
    initData() {
      this.formLoading = true
      getSendtTagDetatl(this.info.id)
        .then((res) => {
          const {
            contents,
            title,
            mediaId,
            accountChannelId,
            channelName,
            ...other
          } = res
          const defaultAccount = this.account.find(
            (item) => item.id === accountChannelId
          )
          this.selectChannel = {
            id: accountChannelId,
            channelName: channelName,
            platform: defaultAccount ? defaultAccount.platform : null,
          }
          this.form = other
          this.form.rfm =
            other.rfm === '0' || other.rfm === ''
              ? []
              : other.rfm.split(',').map((item) => Number(item))
          if (other.bookId) {
            this.defaultBook = {
              bookName: other.bookName,
              id: other.bookId,
            }
          }
          if (other.msgType === 'text') {
            this.textStr = contents
          } else {
            this.selectMaterial = { title, mediaId }
          }
        })
        .finally(() => (this.formLoading = false))
    },
    handleSubmit() {
      if (!this.canSub) return
      const { textStr, selectMaterial, form, info, selectChannel } = this
      const { msgType } = form
      if (this.form.tick) {
        if (this.form.tick < formatTime(new Date(), 'yyyy-MM-dd hh:mm:ss')) {
          return this.$message.error('定时时间需大于当前时间')
        }
      }
      if (!this.selectChannel) {
        this.$message.error('请选择公众号')
        return false
      }
      if (msgType === 'text' && !textStr) {
        this.$message.error('请输入发送内容')
        return false
      }
      if (msgType === 'mpnews' && !selectMaterial) {
        this.$message.error('请选择素材')
        return false
      }
      if (this.form.type === 2 && !this.form.subDay) {
        return this.$message.error('请输入发送间隔天数')
      }
      const params = { ...form, accountChannelId: this.selectChannel.id }

      if (msgType === 'text') {
        params.contents = textStr
      } else {
        params.title = selectMaterial.title
        params.mediaId = selectMaterial.mediaId
      }
      if (info) params.id = info.id
      this.submitLoading = true
      params.rfm =
        Array.isArray(params.rfm) && params.rfm.length > 0
          ? params.rfm.join(',')
          : '0'
      // return;
      editSendTag(params)
        .then(() => {
          this.$message.success('保存成功')
          // this.handleClose();
          this.$emit('close')
          this.canSub = false
          this.$emit('refresh')
        })
        .finally(() => (this.submitLoading = false))
    },
    getBooks(query) {
      this.defaultBook = null
      getBookList({ page: 1, pageSize: 50, bookName: query }).then((res) => {
        this.bookList = res.list
      })
    },
    handleClose() {
      this.$confirm('确定要取消操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$emit('close')
      })
    },
    checkCoinStatus() {
      if (!this.selectChannel) return this.$message.error('请选择公众号')
      this.showBookCoin = true
    },
    handleBookCoinInsert(data) {
      if (this.form.msgType === 'text') {
        this.handleInsert(data.value)
      } else {
        this.form.novelName = data.name
        this.newsForm.url = data.url
      }
    },
    handleSelectSuccess({ link, name, url, bookTitle }) {
      if (this.form.msgType === 'text') {
        this.handleInsert(link)
      } else {
        this.form.novelName = bookTitle ? `${bookTitle}${name}` : `${name}`
        this.newsForm.url = url
      }
    },
    handleActivityInsert(data) {
      if (this.form.msgType === 'text') {
        this.handleInsert(data.join('\n'))
      } else {
        this.form.novelName = data.name
        this.newsForm.url = data.url
      }
    },
  },
  components: {
    SelectMaterial,
    InsertMiniProgram,
    UserAvatar,
    selectTitle,
    InsertTemplate,
    InsertBook,
    InsertPage,
    InsertActivity,
    SendDetailTable,
    InsertBookCoin,
  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__body {
  overflow-y: auto;
}
/deep/ .el-button {
  margin-right: 0px !important;
  margin-left: 0px;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
/deep/ .el-input-number--small {
  width: 150px;
}
.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.form-container {
  width: 1300px;
  margin: auto;
}
.form-container-left {
  width: 600px;
  margin-left: auto;
}
.form-container-right {
  width: 600px;
  margin-right: auto;
}
.inner-form {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 32px;
  padding: 5px 0;
}

/**
**
**改版标签群发排版相关css
**
**/
// 新增属性
.inner-form {
  width: 480px;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
  padding: 10px;
  background-color: #f6f6f6;
  margin-left: -10px;
}
.form-container /deep/ .el-form-item__label {
  font-size: 13px;
}
.form-container /deep/ .el-radio-button--medium .el-radio-button__inner {
  font-size: 13px;
}
.form-container .el-radio__label {
  font-size: 13px;
}
/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}
.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}
.form-top {
  background-color: #fbfbfb;
  padding: 10px 20px 0 20px;
}
/deep/ .necessary > .el-form-item__label {
  position: relative;
}
/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}
.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;
  .message-box {
    justify-content: flex-start;
  }
  .form-body-left {
    border-right: 1px dashed #d9d9d9;
  }
  .form-body-left,
  .form-body-right {
    padding-top: 10px;
    width: 630px;
  }
  .form-body-right {
    padding-left: 85px;
    position: relative;
    .form-body-right-mask {
      z-index: 9999;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#ababab, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        font-size: 16px;
        color: #fff;
      }
    }
    .vip-consume {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-radio-group {
        .el-radio {
          margin: 0 5px !important;
        }
      }
      .consume-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        i {
          margin: 0 10px 10px 0;
        }
      }
    }
    .rfm-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      i {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
}
.dialog-footer {
  .test-box {
    width: 520px;
    margin: auto;
    /deep/ .el-form-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .sub-box {
    display: flex;
    width: 520px;
    margin: auto;
    justify-content: flex-start;
    align-items: center;
    .person-count {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
// 图文框
.image-text-box {
  cursor: pointer;
  display: block;
  width: 450px;
  height: 400px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 15px;
  background-color: #fff;
  color: #8b8b8b;
  line-height: 16px;
  position: relative;
  > p {
    display: inline-block;
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    text-align: center;
    color: #ccc;
  }
}

// 限制输入框高度
.test /deep/ .el-textarea__inner {
  /* border-radius: 0; */
  width: 500px;
  display: block !important;
  position: relative !important;
  margin: 0;
  padding: 0;
  min-height: 400px !important;
  max-height: 400px !important;
  /* height: auto; */
  overflow-y: initial !important;
  /* 设定字体 防止mark标签高亮颜色错位渲染 */
  font-family: 'Microsoft YaHei', Avenir, Helvetica, Arial, sans-serif;
  /* 去掉字体默认的继承属性 */
  /* font-size:'' */
  /* font: inherit;  */
  /* overflow-x: hidden !important; 
	overflow-y: auto !important; */
}
// 图文回显
.material-item {
  margin-top: 5px;
  width: 256px;
  height: 380px;
  padding: 10px;
  margin-right: 20px;
  box-shadow: 0 1px 6px 0 #ccc;
  border-radius: 5px;
  position: relative;
  .article-box {
    position: relative;
    .article-item:first-child {
      width: 236px;
      height: 100px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        margin-left: 0;
      }
      p {
        position: absolute;
        bottom: 5px;
        left: 5px;
        width: 226px;
        height: 30px;
        line-height: 30px;
        padding-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .article-item {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 30px;
        height: 30px;
        background: #666;
        margin-left: 15px;
      }
      p {
        flex: 1;
        display: table-cell;
        vertical-align: middle;
        white-space: normal;
        word-break: break-all;
        line-height: 30px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        font-size: 14px;
      }
    }
  }
}
</style>
