<template>
  <div>
    <div
      class="
        part
        border-radius-6
        margin-top-bottom-twenty
        box-shadow-light-grey
        padding-20
      "
    >
      <div class="flex-between operation padding-bottom-20">
        <div>
          <el-button class="custom-button-margin-right" @click="getList"
            >刷新数据</el-button
          >
          <el-select
            class="margin-right-twentyFour"
            v-model="status"
            placeholder="状态"
            style="width: 140px"
            clearable
            @change="handlePageChange(1)"
          >
            <el-option label="未开始" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <el-option label="已完成" :value="2"></el-option>
            <el-option label="终止" :value="3"></el-option>
          </el-select>
          <el-select
            class="margin-right-twentyFour"
            v-model="type"
            placeholder="标签类型"
            style="width: 140px"
            clearable
            @change="handlePageChange(1)"
          >
            <el-option label="标签发送" :value="1"></el-option>
            <el-option label="关注发送" :value="2"></el-option>
            <el-option label="标签循环" :value="3"></el-option>
          </el-select>
          <el-input
            class="margin-right-twentyFour"
            v-model="copyRelationId"
            style="width: 200px"
            placeholder="请输入消息ID"
            clearable
            @clear="handleClear"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList()"
            ></el-button
          ></el-input>
          <el-button
            class="custom-button-margin-left"
            type="primary"
            @click="handleOpenDraft"
            >开启草稿箱</el-button
          >
          <el-button
            class="custom-button-margin-left"
            type="danger"
            @click="handleDelete(selected)"
            :disabled="selected.length === 0"
            >批量删除
          </el-button>
        </div>
        <el-button
          class="custom-button-margin-left"
          type="primary"
          @click="handleDialogShow(null, 'isEdit')"
          icon="el-icon-plus"
          >新增标签群发</el-button
        >
      </div>
      <el-table
        v-loading="loading"
        :data="messageList"
        height="calc(100vh - 320px)"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="id" width="80" label="ID"> </el-table-column>
        <el-table-column prop="msgType" label="消息类型" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.msgType === 'text'" type="info"
              >文本</el-tag
            >
            <el-tag v-else-if="scope.row.msgType === 'mpnews'">图文</el-tag>
            <el-tag v-else type="danger">未知</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" min-width="150" label="备注">
        </el-table-column>
        <el-table-column prop="hourSpace" width="150" label="时间间隔">
          <template slot-scope="scope">
            {{ scope.row.hourSpace }}
          </template>
        </el-table-column>
        <el-table-column prop="type" min-width="100" label="标签类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type == 2" type="success">关注发送</el-tag>
            <el-tag v-else-if="scope.row.type == 1" type="warning"
              >标签发送</el-tag
            >
            <el-tag v-else-if="scope.row.type == 3" type="danger"
              >标签循环</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="subDay" min-width="100" label="发送间隔">
          <template slot-scope="scope">
            {{ scope.row.subDay ? scope.row.subDay + '天' : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="发送状态" width="150">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0" type="warning">未开始</el-tag>
            <el-tag v-else-if="scope.row.status === 1">进行中</el-tag>
            <el-tag v-else-if="scope.row.status === 2" type="success"
              >已成功</el-tag
            >
            <el-tag v-else-if="scope.row.status === 3" type="danger"
              >终止</el-tag
            >
            <el-tag v-else type="danger">其他</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sendNum" label="发送人数" width="100">
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" min-width="200">
        </el-table-column>
        <el-table-column
          prop="tick"
          label="定时时间"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="startDate"
          label="发送开始时间"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="endDate"
          label="发送结束时间"
          width="200"
        ></el-table-column>
        <el-table-column prop="action" width="270" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="详情"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document"
                circle
                @click="handleDialogShow(scope.row, 'showDetail')"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="复制"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-document-copy"
                circle
                @click="handleCopy(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              v-if="scope.row.status === 0"
              content="编辑"
              placement="top-start"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="handleDialogShow(scope.row, 'isEdit')"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="发送客服消息"
              placement="top-start"
            >
              <el-button
                type="warning"
                v-if="scope.row.repeatSend"
                icon="el-icon-s-promotion"
                circle
                @click="handleSendMesg(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              v-if="scope.row.type != 1"
              content="终止"
              placement="top-start"
            >
              <el-button
                type="info"
                icon="el-icon-video-pause"
                circle
                @click="handleStopSend(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="top-start"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="handleDelete([scope.row.id])"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        @current-change="handlePageChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <edit
      :show="isEdit"
      :account="channelList"
      :info="current"
      :moduleType="3"
      :accountId="accountChannelId"
      @close="isEdit = false"
      @refresh="getList"
    />

    <detail
      :show="showDetail"
      :info="current"
      @close="showDetail = false"
      :moduleType="3"
    />
    <el-dialog title="标签复制" :visible.sync="showCopy" width="350px">
      <el-form label-width="100px">
        <el-form-item label="定时时间：">
          <el-date-picker
            style="width: 200px"
            v-model="copyTime"
            :picker-options="{
              disabledDate(time) {
                return time < Date.now()
              },
            }"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <p class="copy-footer">
          <el-button @click="showCopy = false">取消</el-button>
          <el-button type="primary" @click="handleConfirmCopy">确定</el-button>
        </p>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatTime } from '@/assets/js/utils.js'
import { messagePlatformList } from '@/assets/js/options' //客服消息支持平台
import {
  getSendTagList,
  deleteSendTag,
  copyTag,
  sendTag,
  stopTag,
  openDraft,
} from '../../../api/service'
import Edit from './edit'
// import Edit from '../../service/edit'
// import Detail from './detail'
import Detail from './../../service/detail'

export default {
  name: 'Tag',
  props: ['accountChannelId', 'relationId'],
  data() {
    return {
      loading: false,
      messageList: [],
      current: null,
      isEdit: false,
      showDetail: false,
      status: null,
      type: null,
      page: 1,
      total: 0,
      copyRelationId: null,
      showCopy: false,
      copyRow: {},
      copyTime: '',
      selected: [],
    }
  },
  watch: {
    accountChannelId() {
      this.getList()
    },
  },
  computed: {
    channelList() {
      // return this.officialList.filter((item) => item.status === 1);
      return this.officialList.filter(
        (item) =>
          messagePlatformList.includes(item.platform) && item.status === 1
      )
    },
    ...mapGetters(['officialList']),
  },
  beforeRouteEnter(to, form, next) {
    next((vm) => {
      vm.copyRelationId = vm.relationId
      vm.getList()
    })
  },
  methods: {
    handleSelectionChange(rows) {
      this.selected = rows.map((item) => item.id)
    },
    handleOpenDraft() {
      this.$confirm(
        '此开关开启后不可逆，换言之，无法从开启的状态回到关闭',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        openDraft(this.accountChannelId).then((res) => {
          this.$message.success('开启成功')
        })
      })
    },
    handleStopSend(data) {
      this.$confirm('确定要终止此条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        stopTag(data.id).then((res) => {
          this.$message.success('终止成功')
          this.handlePageChange(this.page)
        })
      })
    },
    handleConfirmCopy() {
      if (this.copyTime) {
        if (this.copyTime < formatTime(new Date(), 'yyyy-MM-dd hh:mm:ss')) {
          return this.$message.error('定时时间需大于当前时间')
        }
      }
      copyTag(this.copyRow.id, {
        tick: this.copyTime,
      }).then((res) => {
        this.$message.success('复制成功')
        this.showCopy = false
        this.getList()
      })
    },
    handleCopy(row) {
      this.showCopy = true
      this.copyRow = row
      this.copyTime = row.tick || ''
    },
    handleSendMesg(item) {
      this.$confirm('确定发送选中群发标签信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          sendTag(item.id).then(() => {
            this.$message.success('发送成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    handlePageChange(page) {
      this.page = page
      this.getList()
    },
    handleDelete(item) {
      this.$confirm('此操作将永久删除选中标签群发, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteSendTag(item.join()).then(() => {
            this.$message.success('删除成功')
            this.getList()
          })
        })
        .catch(() => {})
    },
    handleDialogShow(item, prop) {
      this[prop] = true
      this.current = item
    },
    getList() {
      if (this.accountChannelId) {
        this.loading = true
        getSendTagList(this.accountChannelId, {
          page: this.page,
          pagSize: this.copyRelationId ? 99999 : 15,
          status: this.status,
          type: this.type,
          id: this.copyRelationId ? this.copyRelationId : null,
        })
          .then((res) => {
            this.messageList = res.list
            this.total = res.total
          })
          .finally(() => (this.loading = false))
      }
    },
    formatOffset(sendOffset) {
      return sendOffset
        ? `${Math.floor(sendOffset / 3600)} 时 ${Math.floor(
            (sendOffset % 3600) / 60
          )} 分 ${Math.floor((sendOffset % 3600) % 60)} 秒`
        : 0
    },
    handleClear() {
      this.$emit('update:relationId', null)
      this.copyRelationId = null
      this.page = 1
      this.getList()
    },
  },
  components: {
    Edit,
    Detail,
  },
}
</script>

<style lang="scss" scoped>
.operation {
  background: #fff;
}
.part {
  box-sizing: border-box;
  background: #fff;
}
/deep/ .el-drawer {
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.copy-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
