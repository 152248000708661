<template>
  <div>
    <div class="material-box" v-loading="loading">
      <div
        @click="handleSelected(item)"
        class="material-item"
        v-for="(item, index) in dataList"
        :key="index"
      >
        <ul class="article-box">
          <li
            class="article-item"
            v-for="(item2, index2) in item.title"
            :key="index2"
          >
            <p>{{ item2.title }}</p>
            <img :src="item2.url" />
          </li>
        </ul>
        <div class="checked-mask" v-if="current === item">
          <i class="el-icon-check"></i>
        </div>
      </div>
    </div>
    <el-pagination
      style="text-align: right"
      background
      @current-change="handlePageChange"
      :current-page="page"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <div class="dialog-footer" style="margin: 0 auto; padding: 10px 0">
      <div class="button-box" style="width: 180px; margin: 0 auto">
        <el-button @click="handleClose" style="margin-right: 10px !important"
          >取 消</el-button
        >
        <el-button type="primary" @click="handleSure">确 定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getMaterial } from "../../../api/service";

export default {
  name: "selectTitle",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    accountId: Number,
    materialType: Number,
  },
  data() {
    return {
      title: null,
      dataList: [],
      page: 1,
      total: 0,
      loading: false,
      pageSize: 6,
      current: {},
      loading: false,
    };
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal) {
          this.handlePageChange(1);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSelected(item) {
      this.current = item;
    },
    handleSure() {
      this.$emit("success", this.current);
      this.handleClose();
    },
    handlePageChange(page) {
      this.page = page;
      this.getList();
    },
    getList() {
      this.loading = true;
      getMaterial(this.accountId, {
        page: this.page,
        pageSize: this.pageSize,
        type: "news",
        materialType: this.materialType,
      })
        .then((res) => {
          this.dataList = res.list;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.material-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  height: 600px;
  overflow: auto;
}
.material-item {
  margin-top: 5px;
  width: 256px;
  height: 380px;
  padding: 10px;
  margin-right: 20px;
  box-shadow: 0 1px 6px 0 #ccc;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  .checked-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
    color: #00bf8a;
  }
  .article-box {
    position: relative;
    .article-item:first-child {
      width: 236px;
      height: 100px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        margin-left: 0;
      }
      p {
        position: absolute;
        bottom: 5px;
        left: 5px;
        width: 226px;
        height: 30px;
        line-height: 30px;
        padding-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
    .article-item {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 30px;
        height: 30px;
        background: #666;
        margin-left: 15px;
      }
      p {
        flex: 1;
        display: table-cell;
        vertical-align: middle;
        white-space: normal;
        word-break: break-all;
        line-height: 30px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;
        font-size: 14px;
      }
    }
  }
}
</style>
